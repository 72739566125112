import { MobileStepper } from "@mui/material";
import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { makeStyles } from "tss-react/mui";
import { AnimateComponentOnScroll } from "../../animate-component-on-scroll/AnimateComponentOnScroll";

const useStyles = makeStyles()((theme) => ({
  descriptionSection: {
    display: "flex",
    flexDirection: "column",
    background: "#6D7A8D",
    padding: "60px 0",
    overflow: "hidden",

    [`@media screen and (min-width: 700px)`]: {
      display: "none",
    },
  },
  carouselContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto 30px",
    padding: "10px",
    borderRadius: "8px",
  },
  arrowButton: {
    background: "none",
    border: "none",
    color: "white",
    fontSize: "20px",
    cursor: "pointer",
  },
  itemContainer: {
    width: "200px",
    padding: "10px 20px",
    backgroundColor: "#e0e0ee",
    borderRadius: "12px",
    color: "#000",
    textAlign: "center",
    fontSize: "16px",
    margin: "0 10px",
  },
  descriptionTitle: {
    display: "flex",
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
    margin: "30px 30px",
    color: "#fff",
  },
  descriptionImage: {
    marginLeft: "-25%",
    width: "150%",
  },
  descriptionImageInvis: {
    display: "none",
  },
  descriptionSubtitle: {
    margin: "0 30px",
    marginTop: "30px",
    color: "white",
    fontSize: "16px",
    height: "125px",
  },
  descriptionList: {
    margin: "0 30px",
    marginTop: "20px",
    color: "white",
    fontSize: "15px",
  },
  descriptionListItem: {
    marginBottom: "15px",
  },
  swipeStepper: {
    background: "transparent",
    display: "flex",
    justifyContent: "center",
    marginTop: "3vh",
  },
  swipeText: {
    fontSize: "13px",
    color: "#DDDDDD",
    textAlign: "center",
  },
  dot: {
    backgroundColor: "#DDDDDD",
  },
  dotActive: {
    backgroundColor: "#222222",
  },
  buttonText: {
    ...theme.typography.button1,
    fontWeight: "500",
    margin: 0,
  },
  fadeOut: {
    opacity: 0,
    transition: "opacity 0.2s ease-in-out",
  },
  fadeIn: {
    opacity: 1,
    transition: "opacity 0.2s ease-in-out",
  },
}));

interface DescriptionSectionProps {}

enum DescriptionType {
  Configurator = "Host Configurator",
  Store = "Dashboard Store",
  Alert = "Alert Store",
  Platform = "Dashboard Platform",
}

export const MDescriptionSection: React.FC<DescriptionSectionProps> = () => {
  const { classes } = useStyles();
  const items = [
    DescriptionType.Configurator,
    DescriptionType.Store,
    DescriptionType.Alert,
    DescriptionType.Platform,
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSwiping, setIsSwiping] = useState(false);

  const goToPrevious = () => {
    if (isSwiping) return;
    setIsSwiping(true);
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
    setTimeout(() => setIsSwiping(false), 300);
  };

  const goToNext = () => {
    if (isSwiping) return;
    setIsSwiping(true);
    setCurrentIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
    setTimeout(() => setIsSwiping(false), 300);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => goToNext(),
    onSwipedRight: () => goToPrevious(),
    trackMouse: true,
  });

  return (
    <div className={classes.descriptionSection} {...handlers} id="features">
      <AnimateComponentOnScroll enterDirection="right">
        <>
          <h2 className={classes.descriptionTitle}>
            Designed to dramatically simplify monitoring your Web3
            infrastructure.
          </h2>
          <div className={classes.carouselContainer}>
            <button onClick={goToPrevious} className={classes.arrowButton}>
              {"<"}
            </button>
            <div className={classes.itemContainer}>
              <h3 className={classes.buttonText}>{items[currentIndex]}</h3>
            </div>
            <button onClick={goToNext} className={classes.arrowButton}>
              {">"}
            </button>
          </div>
          <div className={`${isSwiping ? classes.fadeOut : classes.fadeIn}`}>
            <img
              className={
                currentIndex === 0
                  ? classes.descriptionImage
                  : classes.descriptionImageInvis
              }
              src="/images/description-host-configurator.png"
              alt="configurator"
            ></img>

            <img
              className={
                currentIndex === 1
                  ? classes.descriptionImage
                  : classes.descriptionImageInvis
              }
              src="/images/description-dashboard-store.png"
              alt="store"
            ></img>
            <img
              className={
                currentIndex === 2
                  ? classes.descriptionImage
                  : classes.descriptionImageInvis
              }
              src="/images/description-alert-store.png"
              alt="alert-store"
            ></img>
            <img
              className={
                currentIndex === 3
                  ? classes.descriptionImage
                  : classes.descriptionImageInvis
              }
              src="/images/description-dashboard-platform.webp"
              alt="platform"
            ></img>
          </div>
          <div className={`${isSwiping ? classes.fadeOut : classes.fadeIn}`}>
            {currentIndex === 0 && (
              <>
                <div className={classes.descriptionSubtitle}>
                  <strong>Deploy your monitoring stack effortlessly</strong>{" "}
                  with the NMS Host Configurator. Manage all services to be
                  monitored in one easy-to-use interface.
                </div>
                <ul className={classes.descriptionList}>
                  <li className={classes.descriptionListItem}>
                    Install and configure industry standard OS monitoring tools
                    within minutes
                  </li>
                  <li className={classes.descriptionListItem}>
                    Benefit from ready-made configuration templates for all
                    services that a Chainlink node operator manages
                  </li>
                  <li className={classes.descriptionListItem}>
                    Data scraping tools already running? Connect them to NMS
                    with a simple config adjustment
                  </li>
                </ul>
              </>
            )}
            {currentIndex === 1 && (
              <>
                <div className={classes.descriptionSubtitle}>
                  Explore our{" "}
                  <strong>
                    tailor-made dashboards visualizing every crucial metric
                  </strong>{" "}
                  of your nodes and related services—built upon years of
                  industry expertise.
                </div>
                <ul className={classes.descriptionList}>
                  <li className={classes.descriptionListItem}>
                    New dashboards are added promptly and existing ones are
                    updated to reflect the latest Chainlink releases and
                    integrations, ensuring you always stay up to date
                  </li>
                  <li className={classes.descriptionListItem}>
                    Covers dashboards for all services relevant in a Chainlink
                    node environment (Chainlink & RPC nodes, External Adapters,
                    PostgreSQL, HAProxy, etc)
                  </li>
                  <li className={classes.descriptionListItem}>
                    Benefit from LinkRiver's Chainlink-specific monitoring work
                    over the last 3+ years
                  </li>
                </ul>
              </>
            )}
            {currentIndex === 2 && (
              <>
                <div className={classes.descriptionSubtitle}>
                  Leverage{" "}
                  <strong>alerts that have been fine-tuned over years</strong>{" "}
                  to meet the evolving needs of Chainlink node operators. Never
                  miss a critical incident again.
                </div>
                <ul className={classes.descriptionList}>
                  <li className={classes.descriptionListItem}>
                    Alerts are grouped into meaningful bundles and form flexibly
                    configurable evaluation groups
                  </li>
                  <li className={classes.descriptionListItem}>
                    Receive notifications in your familiar channels or try our
                    collaborative solution for interactive Slack alerts
                  </li>
                  <li className={classes.descriptionListItem}>
                    Our OnCall integration lets you easily create team schedules
                    so you are protected around the clock
                  </li>
                </ul>
              </>
            )}
            {currentIndex === 3 && (
              <>
                <div className={classes.descriptionSubtitle}>
                  <strong>
                    Visualize and monitor your entire infrastructure
                  </strong>{" "}
                  on a well-structured platform without missing the slightest
                  incident.
                </div>
                <ul className={classes.descriptionList}>
                  <li className={classes.descriptionListItem}>
                    Your dedicated team organization is specially adapted for
                    Chainlink node environments
                  </li>
                  <li className={classes.descriptionListItem}>
                    Effortlessly navigate between different dashboards and
                    panels of various services
                  </li>
                  <li className={classes.descriptionListItem}>
                    Use NMS to debug faster, more effectively and in depth than
                    ever before
                  </li>
                </ul>
              </>
            )}
          </div>

          <MobileStepper
            className={classes.swipeStepper}
            steps={4}
            position="static"
            activeStep={currentIndex}
            nextButton={null}
            backButton={null}
            classes={{
              dot: classes.dot,
              dotActive: classes.dotActive,
            }}
          />
          <div className={classes.swipeText}>Swipe for more features</div>
        </>
      </AnimateComponentOnScroll>
    </div>
  );
};
