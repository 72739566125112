import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Button, useMediaQuery } from "@mui/material";
import { AnimateComponentOnScroll } from "../../animate-component-on-scroll/AnimateComponentOnScroll";

const useStyles = makeStyles()((theme) => ({
  descriptionSection: {
    width: "100%",
    display: "flex",
    background: "#6D7A8D",
    height: "fit-content",
    padding: "9.3vh 0px",
    overflowX: "hidden",
    justifyContent: "center",
    [`@media screen and (min-width: 2000px)`]: {
      padding: "8vh 0",
    },
    [`@media screen and (max-width: 1600px)`]: {
      padding: "8vh 0px 8vh",
    },
    [`@media screen and (max-width: 700px)`]: {
      display: "none",
    },
  },
  descriptionImages: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "100%",

    [`@media screen and (max-width: 1200px)`]: {
      display: "none",
    },
  },
  descriptionImagesTablet: {
    width: "80%",
    margin: "0 8.5vw",

    [`@media screen and (min-width: 1200px)`]: {
      display: "none",
    },
  },
  descriptionImage: {
    width: "100%",
    padding: "0px",
    transition: "opacity 0.2s ease-in-out",
    opacity: 1,
    [`@media screen and (min-width: 2000px)`]: {
      padding: "0px 1vw",
    },
    [`@media screen and (max-width: 1600px)`]: {
      padding: "0px 20px",
    },
    [`@media screen and (max-width: 1300px)`]: {
      padding: "0px 2.6vw",
    },
    [`@media screen and (max-width: 960px)`]: {
      padding: "2vh 0",
    },
  },
  descriptionText: {
    padding: "0 5% 0 5%",
    [`@media screen and (min-width: 2000px)`]: {
      padding: "0 5% 0 2%",
    },
    [`@media screen and (max-width: 960px)`]: {
      width: "100%",
      padding: "0",
    },
  },
  descriptionTitle: {
    ...theme.typography.h2,
    display: "flex",
    flexDirection: "column",
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
    [`@media screen and (max-width: 1600px)`]: {
      fontSize: "24px",
    },
  },
  descriptionButtons: {
    display: "flex",
    justifyContent: "space-between",
    margin: "6.5vh 0 3.7vh",
    [`@media screen and (max-width: 1600px)`]: {
      margin: "40px 0 15px",
    },
    [`@media screen and (max-width: 960px)`]: {
      margin: "40px 5% 15px",
    },
  },
  selectedDescriptionButton: {
    ...theme.typography.button1,
    lineHeight: "1.1",
    width: "23%",
    height: "5.6vh",
    color: "black",
    background: "white",
    border: "1px solid white",
    borderRadius: "12px",
    "&:hover": {
      border: "0",
      color: "white",
    },
    [`@media screen and (max-width: 1600px)`]: {
      fontSize: "15px",
      height: "40px",
    },
    [`@media screen and (max-width: 1300px)`]: {
      fontSize: "16px",
      height: "50px",
    },
  },
  notSelectedDescriptionButton: {
    ...theme.typography.button1,
    lineHeight: "1.1",
    width: "23%",
    height: "5.6vh",
    color: "white",
    border: "1px solid white",
    borderRadius: "12px",
    "&:hover": {
      border: "0",
    },
    [`@media screen and (max-width: 1600px)`]: {
      fontSize: "15px",
      height: "40px",
    },
    [`@media screen and (max-width: 1300px)`]: {
      fontSize: "16px",
      height: "50px",
    },
  },
  buttonText: {
    fontSize: "1.7vh",
    fontWeight: "500",
    [`@media screen and (max-width: 1600px)`]: {
      fontSize: "14px",
    },
    [`@media screen and (max-width: 1300px)`]: {
      fontSize: "12px",
    },
  },
  descriptionSubtitle: {
    ...theme.typography.button1,
    color: "white",
    padding: "20px",
    [`@media screen and (max-width: 1600px)`]: {
      fontSize: "17px",
    },
    [`@media screen and (max-width: 1300px)`]: {
      fontSize: "18px",
    },
  },
  descriptionList: {
    ...theme.typography.button2,
    color: "white",
    [`@media screen and (max-width: 1600px)`]: {
      fontSize: "15px",
    },
    [`@media screen and (max-width: 1300px)`]: {
      fontSize: "16px",
    },
  },
  descriptionListItem: {
    marginBottom: "1.4vh",
  },
  descriptionImageInvis: {
    display: "none",
    transition: "opacity 0.2s ease-in-out",
  },
  descriptionBox: {
    opacity: 1,
    transition: "opacity 0.2s ease-in-out",
    [`@media screen and (max-width: 960px)`]: {
      height: "250px",
      margin: "0 5%",
    },
    [`@media screen and (max-width: 900px)`]: {
      height: "280px",
    },
    [`@media screen and (max-width: 800px)`]: {
      height: "310px",
    },
  },
  imageBoxHidden: {
    opacity: 0,
    transition: "opacity 0.2s ease-in-out",
  },
  descriptionBoxHidden: {
    opacity: 0,
  },
}));

interface DescriptionSectionProps {}

enum DescriptionType {
  Configurator = "configurator",
  Store = "store",
  Platform = "platform",
  Alert = "alert",
}

export const DescriptionSection: React.FC<DescriptionSectionProps> = () => {
  const { classes } = useStyles();

  const [selectedDescription, setSelectedDescription] =
    useState<DescriptionType>(DescriptionType.Configurator);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const handleDescriptionChange = (newDescription: DescriptionType) => {
    if (selectedDescription === newDescription) return;

    setIsFadingOut(true);

    setTimeout(() => {
      setSelectedDescription(newDescription);
      setIsFadingOut(false);
    }, 200);
  };

  const isSmallScreen = useMediaQuery("(max-width: 1200px)");
  const isMediumScreen = useMediaQuery("(max-width: 1300px)");
  const isBigScreen = useMediaQuery("(max-width: 1600px)");

  const getWidth = (image?: boolean) => {
    if (isSmallScreen) {
      return image ? "0%" : "90%";
    }

    if (isMediumScreen) {
      return image ? "31%" : "69%";
    }

    if (isBigScreen) {
      return image ? "41%" : "59%";
    }

    return image ? "40%" : "50%";
  };

  return (
    <div className={classes.descriptionSection} id="features">
      <AnimateComponentOnScroll enterDirection="left" width={getWidth(true)}>
        <div
          className={`${classes.descriptionImages} ${
            isFadingOut ? classes.imageBoxHidden : ""
          }`}
        >
          <img
            className={
              selectedDescription === DescriptionType.Configurator
                ? classes.descriptionImage
                : classes.descriptionImageInvis
            }
            src="/images/description-host-configurator.png"
            alt="configurator"
          ></img>

          <img
            className={
              selectedDescription === DescriptionType.Store
                ? classes.descriptionImage
                : classes.descriptionImageInvis
            }
            src="/images/description-dashboard-store.png"
            alt="store"
          ></img>
          <img
            className={
              selectedDescription === DescriptionType.Alert
                ? classes.descriptionImage
                : classes.descriptionImageInvis
            }
            src="/images/description-alert-store.png"
            alt="alert-store"
          ></img>
          <img
            className={
              selectedDescription === DescriptionType.Platform
                ? classes.descriptionImage
                : classes.descriptionImageInvis
            }
            src="/images/description-dashboard-platform.webp"
            alt="platform"
          ></img>
        </div>
      </AnimateComponentOnScroll>

      <AnimateComponentOnScroll enterDirection="right" width={getWidth()}>
        <div className={classes.descriptionText}>
          <h2 className={classes.descriptionTitle}>
            Designed to dramatically simplify monitoring your Web3
            infrastructure.
          </h2>
          <div className={classes.descriptionButtons}>
            <Button
              className={
                selectedDescription === DescriptionType.Configurator
                  ? classes.selectedDescriptionButton
                  : classes.notSelectedDescriptionButton
              }
              onClick={(e) => {
                e.preventDefault();
                handleDescriptionChange(DescriptionType.Configurator);
              }}
            >
              <h3 className={classes.buttonText}>Host Configurator</h3>
            </Button>
            <Button
              className={
                selectedDescription === DescriptionType.Store
                  ? classes.selectedDescriptionButton
                  : classes.notSelectedDescriptionButton
              }
              onClick={(e) => {
                e.preventDefault();
                handleDescriptionChange(DescriptionType.Store);
              }}
            >
              <h3 className={classes.buttonText}>Dashboard Store</h3>
            </Button>
            <Button
              className={
                selectedDescription === DescriptionType.Alert
                  ? classes.selectedDescriptionButton
                  : classes.notSelectedDescriptionButton
              }
              onClick={(e) => {
                e.preventDefault();
                handleDescriptionChange(DescriptionType.Alert);
              }}
            >
              <h3 className={classes.buttonText}>Alert Store</h3>
            </Button>
            <Button
              className={
                selectedDescription === DescriptionType.Platform
                  ? classes.selectedDescriptionButton
                  : classes.notSelectedDescriptionButton
              }
              onClick={(e) => {
                e.preventDefault();
                handleDescriptionChange(DescriptionType.Platform);
              }}
            >
              <h3 className={classes.buttonText}>Dashboard Platform</h3>
            </Button>
          </div>
          <div
            className={`${classes.descriptionImagesTablet} ${
              isFadingOut ? classes.imageBoxHidden : ""
            }`}
          >
            <img
              className={
                selectedDescription === DescriptionType.Configurator
                  ? classes.descriptionImage
                  : classes.descriptionImageInvis
              }
              src="/images/description-host-configurator.png"
              alt="configurator"
            ></img>

            <img
              className={
                selectedDescription === DescriptionType.Store
                  ? classes.descriptionImage
                  : classes.descriptionImageInvis
              }
              src="/images/description-dashboard-store.png"
              alt="store"
            ></img>
            <img
              className={
                selectedDescription === DescriptionType.Alert
                  ? classes.descriptionImage
                  : classes.descriptionImageInvis
              }
              src="/images/description-alert-store.png"
              alt="alert-store"
            ></img>
            <img
              className={
                selectedDescription === DescriptionType.Platform
                  ? classes.descriptionImage
                  : classes.descriptionImageInvis
              }
              src="/images/description-dashboard-platform.webp"
              alt="platform"
            ></img>
          </div>
          <div
            className={`${classes.descriptionBox} ${
              isFadingOut ? classes.descriptionBoxHidden : ""
            }`}
          >
            {selectedDescription === DescriptionType.Configurator && (
              <>
                <div className={classes.descriptionSubtitle}>
                  <strong>Deploy your monitoring stack effortlessly</strong>{" "}
                  with the NMS Host Configurator. Manage all services to be
                  monitored in one easy-to-use interface.
                </div>
                <ul className={classes.descriptionList}>
                  <li className={classes.descriptionListItem}>
                    Install and configure industry standard OS monitoring tools
                    within minutes
                  </li>
                  <li className={classes.descriptionListItem}>
                    Benefit from ready-made configuration templates for all
                    services that a Chainlink node operator manages
                  </li>
                  <li className={classes.descriptionListItem}>
                    Data scraping tools already running? Connect them to NMS
                    with a simple config adjustment
                  </li>
                </ul>
              </>
            )}
            {selectedDescription === DescriptionType.Store && (
              <>
                <div className={classes.descriptionSubtitle}>
                  Explore our{" "}
                  <strong>
                    tailor-made dashboards visualizing every crucial metric
                  </strong>{" "}
                  of your nodes and related services—built upon years of
                  industry expertise.
                </div>
                <ul className={classes.descriptionList}>
                  <li className={classes.descriptionListItem}>
                    New dashboards are added promptly and existing ones are
                    updated to reflect the latest Chainlink releases and
                    integrations, ensuring you always stay up to date
                  </li>
                  <li className={classes.descriptionListItem}>
                    Covers dashboards for all services relevant in a Chainlink
                    node environment (Chainlink & RPC nodes, External Adapters,
                    PostgreSQL, HAProxy, etc)
                  </li>
                  <li className={classes.descriptionListItem}>
                    Benefit from LinkRiver's Chainlink-specific monitoring work
                    over the last 3+ years
                  </li>
                </ul>
              </>
            )}
            {selectedDescription === DescriptionType.Alert && (
              <>
                <div className={classes.descriptionSubtitle}>
                  Leverage{" "}
                  <strong>alerts that have been fine-tuned over years</strong>{" "}
                  to meet the evolving needs of Chainlink node operators. Never
                  miss a critical incident.
                </div>
                <ul className={classes.descriptionList}>
                  <li className={classes.descriptionListItem}>
                    Alerts are grouped into meaningful bundles and form flexibly
                    configurable evaluation groups
                  </li>
                  <li className={classes.descriptionListItem}>
                    Receive notifications in your familiar channels or try our
                    collaborative solution for interactive Slack alerts
                  </li>
                  <li className={classes.descriptionListItem}>
                    Our OnCall integration lets you easily create team schedules
                    so you are protected around the clock
                  </li>
                </ul>
              </>
            )}
            {selectedDescription === DescriptionType.Platform && (
              <>
                <div className={classes.descriptionSubtitle}>
                  <strong>
                    Visualize and monitor your entire infrastructure
                  </strong>{" "}
                  on a well-structured platform without missing the slightest
                  incident.
                </div>
                <ul className={classes.descriptionList}>
                  <li className={classes.descriptionListItem}>
                    Your dedicated team organization is specially adapted for
                    Chainlink node environments
                  </li>
                  <li className={classes.descriptionListItem}>
                    Effortlessly navigate between different dashboards and
                    panels of various services
                  </li>
                  <li className={classes.descriptionListItem}>
                    Use NMS to debug faster, more effectively and in depth than
                    ever before
                  </li>
                </ul>
              </>
            )}
          </div>
        </div>
      </AnimateComponentOnScroll>
    </div>
  );
};
