import React from "react";
import { makeStyles } from "tss-react/mui";
import { AnimateComponentOnScroll } from "../../animate-component-on-scroll/AnimateComponentOnScroll";
import { Button } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  videoSection: {
    margin: "8.3vh 18% 11vh",
    overflow: "hidden",
    textAlign: "center",
    height: "100%",
    [`@media screen and (max-width: 1600px)`]: {
      margin: "8vh 12% 10vh",
    },
    [`@media screen and (max-width: 1300px)`]: {
      margin: "7vh 12% 9vh",
    },
    [`@media screen and (max-width: 900px)`]: {
      margin: "5vh 12% 7vh",
    },
    [`@media screen and (max-width: 700px)`]: {
      margin: "60px 12% 80px",
    },
  },
  videoTitle: {
    ...theme.typography.h2,

    [`@media screen and (max-width: 700px)`]: {
      fontSize: "24px",
    },
  },
  videoBox: {
    position: "relative",
    width: "100%",
    marginTop: "5.6vh",
    display: "flex",
    justifyContent: "center",
    [`@media screen and (max-width: 1600px)`]: {
      marginTop: "50px",
    },
  },
  videoWrapper: {
    position: "relative",
    width: "70%",
    paddingTop: "39.5%",
    [`@media screen and (max-width: 1300px)`]: {
      paddingTop: "56.25%",
      width: "100%",
    },
    "&:before": {
      content: '""',
      position: "absolute",
      top: "-2px",
      left: "-2px",
      right: "-2px",
      bottom: "-2px",
      border: "4px solid #fff",
      pointerEvents: "none",
      zIndex: 1,
    },
  },
  video: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    border: 0,
  },
  button: {
    ...theme.typography.button1,
    marginTop: "10.5vh",
    width: "32%",
    height: "5.6vh",
    color: "white",
    background: "#000",
    border: "1px solid white",
    borderRadius: "12px",
    "&:hover": {
      border: "0",
      color: "white",
      background: "linear-gradient(90deg , #25497E, #6D7A8D)",
    },
    [`@media screen and (max-width: 1600px)`]: {
      fontSize: "18px",
      height: "50px",
      margin: "10.5vh 0 0",
    },
    [`@media screen and (max-width: 1300px)`]: {
      margin: "10vh 0 0",
      width: "40%",
    },
    [`@media screen and (max-width: 900px)`]: {
      width: "55%",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "14px",
      width: "79%",
      margin: "9vh auto 0",
      border: "0",
      color: "white",
      background: "linear-gradient(90deg , #25497E, #6D7A8D)",
    },
  },
}));

interface VideoSectionProps {
  id: string;
  title: string;
  link: string;
  videoName: string;
  showButton?: boolean;
  buttonOnClick?: () => void;
  buttonText?: string;
}

export const VideoSection: React.FC<VideoSectionProps> = ({
  id,
  title,
  link,
  videoName,
  showButton = false,
  buttonOnClick,
  buttonText,
}) => {
  const { classes } = useStyles();

  return (
    <div id={id} className={classes.videoSection}>
      <AnimateComponentOnScroll enterDirection="right">
        <div className={classes.videoTitle}>
          <h2 className={classes.videoTitle}>{title}</h2>
        </div>
      </AnimateComponentOnScroll>

      <AnimateComponentOnScroll enterDirection="right">
        <>
          <div className={classes.videoBox}>
            <div className={classes.videoWrapper}>
              <iframe
                className={classes.video}
                src={`${link}?background-color=white&theme=light`}
                title={videoName}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen={true}
              ></iframe>
            </div>
          </div>
          {showButton && (
            <Button
              className={classes.button}
              onClick={(e) => {
                e.preventDefault();

                if (buttonOnClick) {
                  buttonOnClick();
                }
              }}
            >
              {buttonText}
            </Button>
          )}
        </>
      </AnimateComponentOnScroll>
    </div>
  );
};
