import React from "react";
import { BlogSection } from "./blog-section/BlogSection";
import { StartSection } from "./start-section/StartSection";
import { FooterSection } from "./footer-section/FooterSection";
import { DescriptionSection } from "./description-section/DescriptionSection";
import { MDescriptionSection } from "./description-section/MDescriptionSection";
import { ClaimTrialSection } from "./claim-trial-section/ClaimTrialSection";
import { AdvantageSection } from "./advantage-section/AdvantageSection";
import { VideoSection } from "./video-section/VideoSection";

interface LandingPageProps {}

export const LandingPage: React.FC<LandingPageProps> = () => {
  return (
    <>
      <StartSection></StartSection>
      <VideoSection
        id="product-demo"
        title="See how NMS works."
        link="https://www.youtube.com/embed/cNx4WYIsbaI"
        videoName="LinkRiver | Chainlink node operator"
        showButton
        buttonText="Start Your 30-Day Free Trial"
        buttonOnClick={() => {
          window.open("https://calendly.com/nodemonitoring/30", "_blank");
        }}
      ></VideoSection>
      <DescriptionSection></DescriptionSection>
      <MDescriptionSection></MDescriptionSection>
      <AdvantageSection></AdvantageSection>
      <VideoSection
        id="testimonials"
        title="See what Thorsten from Galaxy Digital thinks of NMS."
        link="https://www.youtube.com/embed/yBmzgPZ3c_s"
        videoName="LinkRiver | Chainlink node operator"
      ></VideoSection>
      <ClaimTrialSection></ClaimTrialSection>
      <BlogSection></BlogSection>
      <FooterSection></FooterSection>
    </>
  );
};
